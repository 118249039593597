import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Layout from "../components/Layout";
import Hero from "../components/Hero";
import herobanner from "../assets/images/banners/missions.jpg";
import HeroHeader from "../components/Blog/hero-header";

import BlockTitle from "../components/block-title";
import BlogCard from "../components/Blog/blog-card";

export const getStats = graphql`
  query Mission {
    posts: allContentfulMission {
      edges {
        node {
          location
          missionAreaName
          slug
          featuredImage {
            fluid {
              src
            }
          }
        }
      }
    }
  }
`;

const Mission = () => {
  const { posts } = useStaticQuery(getStats);

  return (
    <Layout>
      <Hero
        bgImage={herobanner}
        title="Global Mission: Reaching to the unreached"
      />

      <section className="container p-4 w-full mx-auto lg:px-8 lg:py-10">
        <div>
          <HeroHeader
            title1="Mission Trips"
            tagLine1="Find domestic and International Mission Trips"
            desc="Mission Trip are the opportunity to get experience in working among the people and making a difference in the live."
          />
          <div class="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-4 sm:grid-cols-2 gap-4">
            {posts.edges.map(({ node }) => (
              <div>
                <BlogCard
                  title={node.missionAreaName}
                  link={node.slug}
                  date={node.location}
                  image={node.featuredImage.fluid.src}
                />
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Mission;
